import React, { useState, useEffect,useMemo} from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  message,
  InputNumber,
  Table,
  Tooltip,
  Tabs,
  AutoComplete,
  Modal,
  Upload
} from "antd";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Country, State } from "country-state-city";
import moment from "moment";
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusCircleOutlined,
   QuestionCircleOutlined,
   MinusCircleOutlined
} from "@ant-design/icons";
import axios from "../../utils/axios";
import {
  API_URL,
  invoiceFrequencyOptions,
  countryCurrency,
} from "../../utils/Constant";
import { useNavigate, Link } from "react-router-dom";
import { agreementOptions } from "../../utils/Constant";
import ButtonComp from "../../components/button/ButtonComp";
import { IoMdArrowRoundBack } from "react-icons/io";
import { toTitleCase } from "../../utils/utils";
import MasterTemplateModal from "../masterTemplate/MasterTempModal";
import ClausesModal from "../clauseTemplate/clauseTemplateModal";
import EditDocComponent from "../../syncFusion/SyncButton";
import useClipboardAutoFill from "../../utils/clipBorad";
import { getAllISOCodes } from "iso-country-currency";
import {isNumberCheck} from '../../../src/utils/service/formValidation'
import './css/createRequest.css'

const { TabPane } = Tabs;

const InitiateRequest = () => {
  const navigate = useNavigate();
  const companyId = localStorage.getItem("company_id");
  const user_role = localStorage.getItem("user_role");

  const [customFields, setCustomFields] = React.useState([]);
  const [receiverEmails, setReceiverEmails] = React.useState([]);
  const token = localStorage.getItem("access_token");
  const [countries, setCountries] = useState();
  const [companyName, setCompanyName] = useState();
  const [businessEntity, setBusinessEntity] = useState();
  const [copyConfirmation, setCopyConfirmation] = useState(false);

  const [clientStates, setClientStates] = useState();
  const [activeTab, setActiveTab] = useState("1");
  // const [filename, setFilename] = useState(null);
  const [templateData, setTemplateData] = useState([]);
  const [masterTemplateData, setMasterTemplateData] = useState([]);
  const [clausesData, setClausesData] = useState([]);
  const [masterClausesData, setMasterClausesData] = useState([]);
  const [autofillDoc, setAutofillDoc] = useState(null);
  // const [autofillDoc, setautofillDoc] = useState(false)

  const [partyStates, setPartyStates] = useState();
  const [partyStatesClicked, setPartyStatesClicked] = useState(false);

  const [products, setProducts] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  const [fileName, setFilename] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [clientCountry, setClientCountry] = useState(null);
  const [partyCountry, setPartyCountry] = useState(null);

  const [fileList, setFileList] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [dateModalVisible, setDateModalVisible] = useState(false);

  const [data, setData] = useState([]);
  const [years, setYears] = useState();
  const [months, setMonths] = useState();
  const [days, setDays] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [clausesModal, setClausesModal] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectedClauseData, setSelectedClauseData] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const { TextArea } = Input;
  const handleYearsChange = (value) => {
    setYears(value);
  };

  const handleMonthsChange = (value) => {
    setMonths(value);
  };

  const handleDaysChange = (value) => {
    setDays(value);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };



  const [form] = Form.useForm();
  const { inputValue, handleInputClick, handleInputChanges } =
    useClipboardAutoFill(form);

  const handleAddCustomField = () => {
    setCustomFields([...customFields, { field: "", value: "" }]);
  };

  const handleRemoveCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };

  const handleCustomFieldChange = (index, field, value) => {
    const updatedFields = [...customFields];
    updatedFields[index] = { field, value };
    setCustomFields(updatedFields);
  };



  const natureOptions = [
    { value: "Customer", label: "Customer" },
    { value: "Vendor", label: "Vendor" },
    { value: "Partnership", label: "Partnership" },
  ];

    const productOptions = useMemo(()=>{
      if(products && Array.isArray(products)){
         return products.map((item, index) => ({
            value: item.product_name,
            label: toTitleCase(item.product_name),
          }));
        }else{
          return []
        }
    }, [products])

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(API_URL + "/country/get/");
        setCountries(response.data); // Update the state with the fetched user data
      }catch{
        console.log('got error')
      }
    }; 
    fetchCompanyDetails();
    fetchCompanyUsers();
    fetchCountry();
    fetchProduct();
    fetchMasterTemplates();
    fetchTemplates();
    countryCurrency();
  }, []);

  const fetchClientState = async () => {
    try {
      if (clientCountry !== null) {
        const response = await axios.get(API_URL + "/state/get/", {
          params: { country_id: clientCountry },
        });
        setClientStates(response.data); // Update the state with the fetched user data
      }
    } catch (error) { }
  };

  const fetchPartyState = async () => {
    try {
      if (partyCountry !== null) {
        const response = await axios.get(API_URL + "/state/get/", {
          params: { country_id: partyCountry },
        });
        setPartyStates(response.data); // Update the state with the fetched user data
      }
    } catch (error) { }
  };

  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get(
        API_URL + `/company/${companyId}/get/`,
        {}
      );
      setCompanyName(response.data.name);
      setBusinessEntity(response.data);
      setPartyCountry(response.data.address.country.id);
      fetchPartyState();
    } catch (error) { }
  };

  useEffect(() => {
    if (clientCountry !== undefined) {
      fetchClientState();
    }
  }, [clientCountry]);

  useEffect(() => {
    if (partyCountry !== undefined) {
      fetchPartyState();
    }
  }, [partyCountry]);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        API_URL + "/company/get_product/",
        config
      );
      setProducts(response.data.data); // Update the state with the fetched user data
    } catch (error) {
      console.error(error)
     }
  };

  const fetchCompanyUsers = async () => {
    try {
      const response = await axios.get(API_URL + "/user/get_user_email/", {
        ...config,
        params: {
          ...config.params,
          is_active: true,
        },
      });
      const newOptions = response.data.data.map((item) => ({
        value: item.email, // Set the value to the email property
        label: item.first_name,
        role: item.role, // Set the label to a relevant property from your API data
         // Set the label to a relevant property from your API data
      }));
      setCompanyUsers(newOptions);
    } catch (error) { }
  };

  const onFinish = (values) => {
    setIsLoading(true);
    const customFieldValues = customFields.reduce((acc, field, index) => {
      const fieldKey = `field${index + 1}`;
      const valueKey = `value${index + 1}`;
      return { ...acc, [fieldKey]: field.field, [valueKey]: field.value };
    }, {});

    const effectiveDate =
      values.effective_date &&
      values.effective_date.format("YYYY-MM-DDTHH:mm:ss");

    // const invoiceRaisingDate =
    //   data && data.invoice_raising_date.format("YYYY-MM-DDTHH:mm:ss");

    let newData = data.map((item) => ({
      ...item,
      invoice_raising_date: item.invoice_raising_date
        ? moment(item.invoice_raising_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
    }));

    const executionDate =
      values.execution_date &&
      values.execution_date.format("YYYY-MM-DDTHH:mm:ss");
    const paymentDueDate =
      values.payment_due_date &&
      values.payment_due_date.format("YYYY-MM-DDTHH:mm:ss");
    const agreementType = values.agreement_type && values.agreement_type.value;
    const nature = values.nature && values.nature.value;

    const country =
      values.client_address && values.client_address.country
        ? values.client_address.country.value
        : "";
    const state =
      values.client_address && values.client_address.state
        ? values.client_address.state.value
        : "";

    const party_country =
      values.party_address && values.party_address.country
        ? values.client_address.country.value
        : "";
    const party_state =
      values.party_address && values.party_address.state
        ? values.party_address.state.value
        : "";

    let formData = {
      ...values,
      custom_field: JSON.stringify(customFieldValues),
      effective_date: effectiveDate,
      execution_date: executionDate,
      payment_due_date: paymentDueDate,
      party_address: JSON.stringify({
        ...(values.party_address || {}),
        country: party_country,
        state: party_state,
      }),
      client_address: JSON.stringify({
        ...(values.client_address || {}),
        country: country,
        state: state,
      }),

      agreement_type: agreementType,
      nature: nature,
      receiver_email: selectedEmails && selectedEmails.toString(),
      agreement_file: fileName && fileName,
      tenure: `${years} Years ${months} Months ${days} Days`,
      product_list: JSON.stringify(newData || {}),
    };

    formData.status = "FRESH_REQUEST";
  };

  const handleClientCountry = (values) => {
    if (values) {
      setClientCountry(values.value);
    } else {
      setClientCountry(null);
    }
  };

  const handlePartyCountry = (values) => {
    if (values) {
      setPartyCountry(values.value);
    } else {
      setPartyCountry(null);
    }
    form.setFieldsValue({
      party_address: {
        state: null // or an empty string "", or whatever is appropriate
      }
    });
    
    setPartyStatesClicked(true);
    // fetchPartyState();
  };
  const handleProductChange = (values) => {
    setSelectedProducts(values);
  };
  const handleReceiversEmailChange = (values) => {
    const selectedValues = Object.values(values).map((item) => item.value);
    setSelectedEmails(selectedValues);
  };

  useEffect(() => {
    const tempRow = [];
    const selectedProductArr = selectedProducts.map((product) =>
      product.label.toLowerCase()
    );
    const dataArr = data.map((row) => row.product_name.toLowerCase());
    data.forEach((row) => {
      if (selectedProductArr.includes(row.product_name.toLowerCase()))
        tempRow.push(row);
    });
    selectedProducts.forEach((prod) => {
      if (!dataArr.includes(prod.label.toLowerCase())) {
        tempRow.push({
          product_name: prod.value,
          invoice_frequency: null,
          credit_term: null,
          invoice_raising_date: null,
          value: null,
          custom_fields: null,
        });
      }
    });
    setData(tempRow);
  }, [selectedProducts]);

  const handleInputChange = (index, columnName, value) => {
    console.log("Update Request:");
    console.log(`- Index: ${index}`);
    console.log(`- Column Name: ${columnName}`);
    console.log(`- New Value: ${value}`);
  
    // console.log(value, "asdasdasdsdasd");
    const newData = [...data];
    newData[index][columnName] = value;
    console.log("Updated Data: ", newData);

    setData(newData);
  };
  

  const handleAddRow = (index) => {
    const clickedProduct = data[index];
    const newData = [...data];

    const newRow = {
      product_name: clickedProduct.product_name, // Add other properties and set them to initial values
      invoice_frequency: null,
      credit_term: null,
      invoice_raising_date: null,
      value: null,
      custom_fields: null,
    };

    newData.splice(index + 1, 0, newRow);

    setData(newData);
  };
  const handleRemoveRow = (index) => {
    // Remove the row at the specified index
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData([...updatedData]);
    console.log(updatedData, 'updatedData 009')
  };

  const countryCurrency = async () => {
    try {
      const codes = await getAllISOCodes();
      const transformedData = codes.map((code) => ({
        value: `${code.symbol}+${code.countryName}`,
        label: `${code.countryName} (${code.symbol})`,
      }));
      console.log("errorCheck", codes, transformedData);

      setCurrencyOptions(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      title: "Products / Services",
      // dataIndex: "product",
      render: (_, record, index) => (
        <>
          <h1>{record.product_name}</h1>
        </>
      ),
    },
    {
      title: (
        <>
          Invoice Frequency{" "}
          <Tooltip title="One Time/ Monthly/ Quarterly/ Half Yearly  ">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "invoice_frequency",
      render: (_, record, index) => (
        <Select
          key={`record-${record.invoice_frequency || index}`}
          styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          defaultValue={() =>
            record.invoice_frequency &&
            invoiceFrequencyOptions.find(
              (option) =>
                option.value.toLowerCase() ===
                record.invoice_frequency.toLowerCase()
            )
          }
          // isClearable
          options={invoiceFrequencyOptions}
          onChange={(selectedOption) =>
            handleInputChange(index, "invoice_frequency", selectedOption.value)
          }
        />
      ),
    },
    {
      title: (
        <>
          Invoice Raising Date{" "}
          <Tooltip title="7th of every succeeding month/ 7th of every month.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "invoice_raising_date",
      render: (_, record, index) => (
          <Input
            value={record.invoice_raising_date}
            onChange={(e) =>
              handleInputChange(index, "invoice_raising_date", e.target.value)
            }
          />
      ),
    },
    {
      title: (
        <>
          Credit Term{" "}
          <Tooltip title="Maximum Credit Period Allowed">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "credit_term",
      render: (_, record, index) => (
        <Input
          value={record.credit_term}
          onChange={(e) =>
            handleInputChange(index, "credit_term", e.target.value)
          }
        />
      ),
    },


    {
      title: (
        <>
          Contract Currency{" "}
          <Tooltip title="Select the currency of contract.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "contract_currency",
      render: (_, record, index) => (
         
          <div  key={record?.contract_currency?.toLowerCase()}  className="w-52">
          <Select
            styles={{
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
            defaultValue={() =>(
              record?.contract_currency &&
               currencyOptions.find(
                (option) =>
                  option.value.toLowerCase() ===
                  record?.contract_currency?.toLowerCase()
              ) )
            }
            options={currencyOptions}
            onChange={(selectedOption) => {
                // Handle logic for selected option
                 if(selectedOption.value === "₹+India"){
                  handleInputChange(index, "value", record.contract_currency_value);   
                  handleInputChange(index, "conversion_rate", null);   
                 }
                handleInputChange(index, "contract_currency", selectedOption.value);
              }}
          />
        </div>
      ),
    },
    {
      title: (
        <>
          Contract Currency Value ID1{" "}
          <Tooltip title="Converted currency value.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "contract_currency_value",
      render: (_, record, index) => (
        <Input
          value={record.contract_currency_value || ''}
          className="disabled:bg-transparent text-black"
          onChange={(e) =>{
                      const inputValue = e.target.value;
                       if (isNumberCheck(inputValue)) {
                        let contract_currency_value;
                        if(record.contract_currency === "₹+India" || !record.conversion_rate){
                          contract_currency_value = inputValue;
                        }else{
                        contract_currency_value =  record.conversion_rate * inputValue;
                        }
                        handleInputChange(index, "contract_currency_value", inputValue);
                        handleInputChange(index, "value", contract_currency_value);
                        if(!inputValue){
                          handleInputChange(index, "conversion_rate", null); 
                        }
                        return ;
                       }
                        message.error("Please enter only numerical value.");
                    }
                    }
        />
      ),
    },

    {
      title: (
        <>
          Conversion Rate{" "}
          <Tooltip title="Enter conversion rate from base currency to contract currency.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "conversion_rate",
      render: (_, record, index) => (
        <Input
          disabled={
            !record.contract_currency_value 
                || record.contract_currency === "₹+India"
          }
          value={record.conversion_rate || ''}
         onChange={(e) => {
                     const inputValue = e.target.value;
                     if (isNumberCheck(inputValue)) {
                       let contract_currency_value;
                       if(!record.contract_currency_value && inputValue){
                        contract_currency_value = inputValue ;
                       }else if(record.contract_currency_value && !inputValue){
                        contract_currency_value = record.contract_currency_value;
                       }else{
                        contract_currency_value = record.contract_currency_value * inputValue;
                       }
                       // Update conversion_rate and value fields
                       handleInputChange(index, "conversion_rate", inputValue); // Keep original inputValue for precision
                       handleInputChange(index, "value", contract_currency_value);
                       return ;
                      }
                      message.error("Please enter only numerical value.");
            }}
        />
      ),
    },


    {
      title: (
        <>
          Base Currency Value{" "}
          <Tooltip title="Total Contract Value">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "value",
      render: (_, record, index) => {    
        return (
          <Input
            disabled
            value={record.value}
            prefix={<span>India (₹)</span>} // Display "India (₹)" as prefix
            className="disabled:bg-transparent text-black"
          />
        );
      },
    }
,    
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddRow(index)}
            style={{ marginRight: 8 }}
          />
          {data.filter((item) => item.product_name === record.product_name).length > 1 && (
            <Button
              type="danger"
              icon={<MinusOutlined />}
              onClick={() => handleRemoveRow(index)}
            />
          )}
        </>
      ),
    },
  ]
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleNext = async () => {
    try {
      // await form.validateFields();
      const fieldsToValidate = getFieldsToValidate(); // Implement this function
      await form.validateFields(fieldsToValidate);

      const nextTab = (parseInt(activeTab, 10) % 6) + 1;
      setActiveTab(nextTab.toString());
    } catch (errorInfo) { }
  };
  const getFieldsToValidate = () => {
    switch (activeTab) {
      case "1":
        return [
          "name_of_client",
          // "name_of_authorised_signatory_of_party",
          // "client_address.address1",
          // "client_address.address2",
          // "client_address.city",
          // "client_address.postal_code",
          // "client_address.country",
          // "client_address.state",
        ];
      case "2":
        return [
          "party",
          // "name_of_authorised_signatory_of_client",
          // "party_address.address1",
          // "party_address.address2",
          // "party_address.city",
          // "party_address.postal_code",
          // "party_address.country",
          // "party_address.state",
        ];
      case "3":
        return [
          "agreement_type",
          "nature",
          // "termination_notice",
          // "jurisdiction",
          // "effective_date",
          // "execution_date",
          // "status",
          // "remarks",
          // "tenure",
          // "agreement_file",
        ];
      case "4":
        return ["product_list"];
      // case "5":
      //   return ["custom_fields"];
      // case "6":
      //   return ["receivers_email"];
      default:
        return ["receivers_email"];
    }
  };
  const getTabIndexForField = (fieldName) => {
    switch (fieldName[0]) {
      case "name_of_client":
      case "name_of_authorised_signatory_of_party":
      case "client_address.address1":
      case "client_address.address2":
      case "client_address.city":
      case "client_address.postal_code":
      case "client_address.country":
      case "client_address.state":
        return "1";
      case "party":
      case "name_of_authorised_signatory_of_client":
      case "party_address.address1":
      case "party_address.address2":
      case "party_address.city":
      case "party_address.postal_code":
      case "party_address.country":
      case "party_address.state":
        return "2";
      case "agreement_type":
      case "nature":
      case "termination_notice":
      case "jurisdiction":
      case "effective_date":
      case "execution_date":
      case "status":
      case "remarks":
      case "tenure":
      case "agreement_file":
        return "3";
      case "product_list":
        return "4";
      case "custom_fields":
        return "5";
      case "receivers_email":
        return "6";
      default:
        return "1"; // Default to the first tab
    }
  };
  const handlePrevious = () => {
    const previousTab = ((parseInt(activeTab, 10) - 2 + 6) % 6) + 1;
    setActiveTab(previousTab.toString());
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await form.validateFields();
      const values = await form.getFieldsValue();
      const customFieldValues = customFields.reduce((acc, field, index) => {
        const fieldKey = `field${index + 1}`;
        const valueKey = `value${index + 1}`;
        return { ...acc, [fieldKey]: field.field, [valueKey]: field.value };
      }, {});
      const effectiveDate =
        values.effective_date &&
        values.effective_date.format("YYYY-MM-DDTHH:mm:ss");

      // let newData = data.map((item) => ({
      //   ...item,
      //   invoice_raising_date: item.invoice_raising_date
      // }));

      const executionDate =
        values.execution_date &&
        values.execution_date.format("YYYY-MM-DDTHH:mm:ss");
      const paymentDueDate =
        values.payment_due_date &&
        values.payment_due_date.format("YYYY-MM-DDTHH:mm:ss");

      const country =
        values.client_address && values.client_address.country
          ? values.client_address.country.value
          : "";
      const state =
        values.client_address && values.client_address.state
          ? values.client_address.state.value
          : "";

      const party_country =
        values.party_address && values.party_address.country
          ? values.client_address.country.value
          : businessEntity &&
            businessEntity.address &&
            businessEntity.address.country
            ? businessEntity.address.country.id
            : "";
      const party_state =
        values.party_address && values.party_address.state
          ? values.party_address.state.value
          : businessEntity &&
            businessEntity.address &&
            businessEntity.address.state
            ? businessEntity.address.state.id
            : "";
      const partyFieldValue = form.getFieldValue("party");

      let formData = {
        ...values,
        custom_field: JSON.stringify(customFieldValues),
        effective_date: effectiveDate,
        execution_date: executionDate,
        payment_due_date: paymentDueDate,

        party_address: JSON.stringify({
          ...(values.party_address || {}),
          country: party_country,
          state: party_state,
        }),
        party: partyFieldValue || companyName || "",

        // agreement_type: agreementType,
        // nature: nature,
        // receiver_email: selectedEmails && selectedEmails.toString(),
        agreement_file: fileName && fileName,
        tenure: `${years ? years : 0} Years ${months ? months : 0} Months ${days ? days : 0
          } Days`,
        product_list: JSON.stringify(data || {}),
      };
      formData.status = "FRESH_REQUEST";

      if (
        country ||
        state ||
        values.client_address?.address1 ||
        values.client_address?.address2 ||
        values.client_address?.city ||
        values.client_address?.postal_code
      ) {
        formData.client_address = JSON.stringify({
          ...(values.client_address || {}),
          ...(country && { country }),
          ...(state && { state }),
          ...(values.client_address?.address1 && {
            address1: values.client_address.address1,
          }),
          ...(values.client_address?.address2 && {
            address2: values.client_address.address2,
          }),
          ...(values.client_address?.city && {
            city: values.client_address.city,
          }),
          ...(values.client_address?.postal_code && {
            postal_code: values.client_address.postal_code,
          }),
        });
      }

      if (
        party_country ||
        party_state ||
        values.party_address?.address1 ||
        values.party_address?.address2 ||
        values.party_address?.city ||
        values.party_address?.postal_code
      ) {
        formData.party_address = JSON.stringify({
          ...(values.party_address || {}),
          ...(party_country && { country: party_country }),
          ...(party_state && { state: party_state }),
          ...(values.party_address?.address1 && {
            address1: values.party_address.address1,
          }),
          ...(values.party_address?.address2 && {
            address2: values.party_address.address2,
          }),
          ...(values.party_address?.city && {
            city: values.party_address.city,
          }),
          ...(values.party_address?.postal_code && {
            postal_code: values.party_address.postal_code,
          }),
        });
      }
      formData.party_address = JSON.parse(formData.party_address);
      Object.keys(formData.party_address).forEach(
        (key) =>
          formData.party_address[key] === "" &&
          delete formData.party_address[key]
      );
      formData.party_address = JSON.stringify(formData.party_address);

      if (selectedEmails && selectedEmails.length > 0) {
        formData.receiver_email = selectedEmails.toString();
      }
      if (selectedTemplate) {
        formData.master_template_id = selectedTemplate.id;
      }
      if (selectedClauseData.length > 0) {
        formData.clause_list = JSON.stringify(selectedClauseData);
      }

      axios
        .post(`${API_URL}/contract/create/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          message.success("Request created successfully.");
          setIsLoading(false);
          navigate("/admin/contracts");
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(error.response.data.error);
        });

      // You can perform additional actions with the form values here
    } catch (errorInfo) {
      setIsLoading(false);
      // setActiveTab("1");
      const errorFields = errorInfo.errorFields[0].name;

      const firstErrorField = errorFields;
      const tabIndex = getTabIndexForField(firstErrorField);
      message.error(`Form validation failed, please check all the fields.`);

      setActiveTab(tabIndex);
    }
  };

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(API_URL + `/contract/master-template/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      setTemplateData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchMasterTemplates = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        API_URL + `/contract/get-master-template/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      setMasterTemplateData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const ftechClauses = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(API_URL + `/contract/clause/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setIsLoading(false);
      setClausesData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const fetchMasterClauses = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        API_URL + `/contract/get-master-clause/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setIsLoading(false);
      setMasterClausesData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAutofillDoc = async () => {
    console.log(fileName, 'fileName 007')
    try {
      setIsLoading(true);
      const response = await axios.post(
        API_URL + `/contract/upload_for_autofill/`,
        { file: fileName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      setCopyConfirmation(false);
      setAutofillDoc(response.data.Fileurl);
      // setautofillDoc(true)
    } catch (error) {
      setIsLoading(false);
      setCopyConfirmation(false);
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  // const handleOptionChange = (value) => {
  //   setSelectedOption(value);
  // };

  const options = [
    { value: "local", label: "From Device" },
    { value: "masterTemplate", label: "Master Template" },
    { value: "clauses", label: "Clauses" },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    // Show the modal when "Master Template" is selected
    if (value && value.value === "masterTemplate") {
      setModalVisible(true);
    } else if (value && value.value === "clauses") {
      setClausesModal(true);
      ftechClauses();
      fetchMasterClauses();
    } else {
      setSelectedTemplate([]);
      setSelectedClauseData([]);
    }
  };

  const handleModalCancel = () => {
    setModalVisible(false);
    setClausesModal(false);
  };

  const handleTemplateSelect = (data) => {
    setSelectedTemplate(data);
  };

  const handleSequenceChange = (id, sequence) => {
    setClausesData(
      clausesData.map((item) => {
        if (item.id === id) {
          return { ...item, sequence };
        }
        return item;
      })
    );
  };

  const handleRowClick = (record) => {};
  const handleClauseSubmit = (data) => {
    setSelectedClauseData(data);
  };
  const handelRemoveAgreementFile=()=>{
    console.log('handelRemoveAgreementFile 006 ')
    setFilename({});
    // setCopyConfirmation(false);
    // setAutofillDoc('')
    // setautofillDoc(false)
    const values = form.getFieldsValue();
    console.log(values, fileName, copyConfirmation);
    // form.setFieldsValue({
    //   agreement_file: '' 
    // });
    form.setFieldsValue({
      agreement_file: ''
    });

  }

  const beforeUpload = (file) => {
    // You can perform additional checks here before allowing file upload
    const isDoc = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!isDoc) {
      message.error('You can only upload .doc or .docx files!');
      return false;
    }
    return isDoc; // Return false to prevent the upload if not .doc or .docx
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "84vh",
        position: "relative",
      }}
    >
      <div className="p-3">
        <Link to="/admin/contracts">
          <IoMdArrowRoundBack size="20px" />
        </Link>
      </div>
      <div className="grow agreement-form">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          encType="multipart/form-data"
          // className={`w-${ !!autofillDoc ? "[50%]" : "[100%]"}`}
          className="autofill"
        >
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            type="card"
            
          // tabBarStyle={{ borderBottom: "1px solid #e8e8e8" }} // Default border for all tabs
          >
            <TabPane  tab="Business Partner" key="1">
              <Row>
                <Col  span={ !!autofillDoc ? 9 : 24}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Business Partner Name"
                        name="name_of_client"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Business Partner Name",
                          },
                        ]}
                      >
                        <Input
                          required
                          value={inputValue["name_of_client"] || ""} // Use value instead of defaultValue
                          onClick={() => handleInputClick(["name_of_client"])}
                          onChange={(e) =>
                            handleInputChanges(e, ["name_of_client"])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Authorised Signatory's Name of Business Partner"
                        name="name_of_authorised_signatory_of_party"
                      >
                        <Input
                          required
                          value={
                            inputValue[
                            "name_of_authorised_signatory_of_party"
                            ] || ""
                          } // Use value instead of defaultValue
                          onClick={() =>
                            handleInputClick([
                              "name_of_authorised_signatory_of_party",
                            ])
                          }
                          onChange={(e) =>
                            handleInputChanges(e, [
                              "name_of_authorised_signatory_of_party",
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Address 1"
                        name={["client_address", "address1"]}
                      >
                        <Input
                          className="custom-placeholder"
                          value={
                            inputValue["client_address"]?.["address1"] || ""
                          }
                          onClick={() =>
                            handleInputClick(["client_address", "address1"])
                          }
                          onChange={(e) =>
                            handleInputChanges(e, [
                              "client_address",
                              "address1",
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Address 2"
                        name={["client_address", "address2"]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Postal Code"
                        name={["client_address", "postal_code"]}
                      >
                        <Input
                          maxLength={6}
                          onKeyPress={(event) => {
                            const isValidInput = /^[0-9\b]+$/.test(event.key);
                            if (!isValidInput) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="City" name={["client_address", "city"]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Country"
                        name={["client_address", "country"]}
                      >
                        <Select
                          options={
                            countries &&
                            countries.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))
                          }
                          onChange={(e)=>{
                            form.setFieldsValue({
                              client_address: {
                                state: null // or an empty string "", or whatever is appropriate
                              }
                            });
                            handleClientCountry(e)
                          }}
                          isSearchable
                          menuPlacement="top" // Set menuPlacement to "top"
                          isClearable
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="State"
                        name={["client_address", "state"]}
                      >
                        <Select
                        disabled ={!clientCountry}
                          options={
                            clientStates &&
                            clientStates.map((state) => ({
                              value: state.id,
                              label: state.name,
                            }))
                          }
                          menuPlacement="top" // Set menuPlacement to "top"
                          isClearable
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                { autofillDoc ? (
                  <Col span={15} style={{ marginTop:'-55px',overflow: 'auto' }} justifyContent="start">
                    <EditDocComponent
                      fileUrl={ !!autofillDoc ? autofillDoc : ''}
                      userName={localStorage.getItem("user_name")}
                      isSave={false}
                    />
                  </Col>
                ) : ''}
              </Row>
            </TabPane>
            <TabPane tab="Business Entity" key="2">
              <Row>
                <Col span={ !!autofillDoc ? 9 : 24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="Business Entity Name" name="party">
                        <Input
                          required
                          defaultValue={companyName || ""}
                          value={inputValue["party"] || ""} // Use value instead of defaultValue
                          onClick={() => handleInputClick(["party"])}
                          onChange={(e) => handleInputChanges(e, ["party"])}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Authorised Signatory's Name of Business Entity"
                        name="name_of_authorised_signatory_of_client"
                      >
                        <Input
                          value={
                            inputValue[
                            "name_of_authorised_signatory_of_client"
                            ] || ""
                          } // Use value instead of defaultValue
                          onClick={() =>
                            handleInputClick([
                              "name_of_authorised_signatory_of_client",
                            ])
                          }
                          onChange={(e) =>
                            handleInputChanges(e, [
                              "name_of_authorised_signatory_of_client",
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Address 1"
                        name={["party_address", "address1"]}
                        initialValue={businessEntity?.address?.address1 || ""}
                      >
                        <Input
                          // required
                          defaultValue={
                            businessEntity &&
                              businessEntity.address &&
                              businessEntity.address.address1
                              ? businessEntity.address.address1
                              : ""
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Address 2"
                        name={["party_address", "address2"]}
                        initialValue={businessEntity?.address?.address2 || ""}
                      >
                        <Input
                          // required
                          defaultValue={
                            businessEntity &&
                              businessEntity.address &&
                              businessEntity.address.address2
                              ? businessEntity.address.address2
                              : ""
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Postal Code"
                        name={["party_address", "postal_code"]}
                        initialValue={
                          businessEntity?.address?.postal_code || ""
                        }
                      >
                        <Input
                          // required
                          maxLength={6}
                          onKeyPress={(event) => {
                            const isValidInput = /^[0-9\b]+$/.test(event.key);
                            if (!isValidInput) {
                              event.preventDefault();
                            }
                          }}
                          defaultValue={
                            businessEntity &&
                              businessEntity.address &&
                              businessEntity.address.postal_code
                              ? businessEntity.address.postal_code
                              : ""
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="City"
                        name={["party_address", "city"]}
                        initialValue={businessEntity?.address?.city || ""}
                      >
                        <Input
                          // required
                          defaultValue={
                            businessEntity &&
                              businessEntity.address &&
                              businessEntity.address.city
                              ? businessEntity.address.city
                              : ""
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Country"
                        name={["party_address", "country"]}
                      >
                        <Select
                          options={
                            countries &&
                            countries.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))
                          }
                          onChange={handlePartyCountry}
                          // required
                          defaultValue={
                            businessEntity &&
                            businessEntity.address &&
                            businessEntity.address.country && {
                              value: businessEntity.address.country.id,
                              label: businessEntity.address.country.name,
                            }
                          }
                          menuPlacement="top" // Set menuPlacement to "top"
                          isClearable
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="State"
                        name={["party_address", "state"]}
                      >
                        <Select
                        disabled={partyCountry}
                          options={
                            partyStates &&
                            partyStates.map((state) => ({
                              value: state.id,
                              label: state.name,
                            }))
                          }
                          // required
                          defaultValue={
                            businessEntity &&
                            businessEntity.address &&
                            businessEntity.address.state && {
                              value: businessEntity.address.state.id,
                              label: businessEntity.address.state.name,
                            }
                          }
                          menuPlacement="top" // Set menuPlacement to "top"
                          isClearable
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                { autofillDoc ? (
                  <Col span={15} style={{ marginTop:'-55px',overflow: 'auto' }} justifyContent="start">
                    <EditDocComponent
                      fileUrl={ !!autofillDoc ? autofillDoc : ''}
                      userName={localStorage.getItem("user_name")}
                      isSave={false}
                    />
                  </Col>
                ) : ''}
              </Row>
            </TabPane>
            <TabPane   tab="Agreement Details" key="3">
              <Row gutter={16}>
                <Col span={ !!autofillDoc ? 9 : 24}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Agreement"
                        name="agreement_type"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Ageement Type",
                          },
                        ]}
                      >
                        <AutoComplete
                          options={agreementOptions.map((option) => ({
                            value: option.value,
                          }))}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          placeholder="Select or type to add agreement"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Nature"
                        name="nature"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Nature",
                          },
                        ]}
                      >
                        {/* <Select options={natureOptions} required /> */}
                        <AutoComplete
                          options={natureOptions.map((option) => ({
                            value: option.value,
                          }))}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          placeholder="Select or type to add nature"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item
                        label="Termination Notice"
                        name="termination_notice"
                      >
                        <Input
                          className="custom-placeholder"
                          value={inputValue["termination_notice"] || ""} // Use value instead of defaultValue
                          onClick={() =>
                            handleInputClick(["termination_notice"])
                          }
                          onChange={(e) =>
                            handleInputChanges(e, ["termination_notice"])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="Jurisdiction" name="jurisdiction">
                        <Input
                          value={inputValue["jurisdiction"] || ""} // Use value instead of defaultValue
                          onClick={() => handleInputClick(["jurisdiction"])}
                          onChange={(e) =>
                            handleInputChanges(e, ["jurisdiction"])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="Effective Date" name="effective_date">
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="Execution Date" name="execution_date">
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                          // required
                          disabledDate={(current) => current && current < moment().startOf("day")}
                          onChange={(date, dateString) => {
                            const selectedDate = new Date(date);
                            const today = new Date();
                            // Check if selected date is in the future (excluding today)
                            if (selectedDate > today) {
                              // window.alert("Selected date is in the future!");
                              setDateModalVisible(true);
                            }
                          }}
                        />
                      </Form.Item>
                      <Modal
                        title="Warning"
                        open={dateModalVisible}
                        onOk={() => setDateModalVisible(false)}
                        onCancel={() => setDateModalVisible(false)}
                        okText="Yes"
                        cancelText="No"
                      >
                        You have selected a future date as Execution Date. Press
                        ‘Yes’ if you intend to proceed with the selected future
                        date.
                      </Modal>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="Status" name="status">
                        <Input disabled defaultValue="Fresh Request" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Form.Item label="Tenure" name="tenure">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row", // Change from "row" to "column"
                            alignItems: "center",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span className="tenure-input-text">
                            <InputNumber
                              min={0}
                              size="large"
                              value={years}
                              onChange={handleYearsChange}
                              placeholder="Years"
                            />
                          </span>
                          {/* <span className="border p-[.5rem] rounded-lg mx-2">
                          <h1>Months</h1>
                        </span> */}
                          <span className="tenure-input-text">
                            <InputNumber
                              min={0}
                              size="large"
                              value={months}
                              onChange={handleMonthsChange}
                              placeholder="Months"
                            />
                          </span>
                          {/* <span className="border p-[.5rem] rounded-lg mx-2">
                          <h1>Days</h1>
                        </span> */}
                          <span className="tenure-input-text">
                            <InputNumber
                              min={0}
                              size="large"
                              value={days}
                              onChange={handleDaysChange}
                              placeholder="Days"
                            />
                          </span>
                        </div>
                      </Form.Item>
                    </Col>

                    {user_role === "Agreement controller Head" || user_role === "Agreement controller Team" ? (
                      <Col xs={24} sm={24} md={12} lg={12} className="mb-4">
                        <h1 className="mb-1">Agreement</h1>

                        <Select
                          options={options}
                          value={selectedOption}
                          onChange={handleOptionChange}
                          placeholder="Select Agreement"
                          isClearable
                        />
                        {selectedOption && selectedOption.value === "local" ? (
                          <>
                          <Form.Item
                            label=""
                            name="agreement_file"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e.fileList}
                            className="mt-2"
                          >
                            <Input
                              type="file"
                              onChange={(e) => {
                                setFilename(e.target.files[0]);
                                setCopyConfirmation(true);
                               
                              }}
                              accept=".doc, .docx"
                              className="form-control"
                              beforeUpload={() => false}
                            ></Input>
                          </Form.Item>
                          {/* {!!fileName ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button ml-3"
                                onClick={() =>{handelRemoveAgreementFile()}}
                              />
                            ) : null} */}
                            </>
                        ) : selectedOption &&
                          selectedOption.value === "masterTemplate" ? (
                          <>
                            <MasterTemplateModal
                              visible={modalVisible}
                              onCancel={handleModalCancel}
                              onSelect={handleTemplateSelect}
                              data={templateData}
                              masterTempData={masterTemplateData}
                            />
                            {selectedTemplate && (
                              <div>
                                <br />
                                Template Name:{" "}
                                <span
                                  className={`bg-blue-500 text-white px-2 py-1 rounded w-full`}
                                >
                                  {selectedTemplate.template_name}
                                </span>
                              </div>
                            )}
                          </>
                        ) : selectedOption &&
                          selectedOption.value === "clauses" ? (
                          <ClausesModal
                            visible={clausesModal}
                            onCancel={handleModalCancel}
                            clausesData={clausesData}
                            masterClausesData={masterClausesData}
                            handleSequenceChange={handleSequenceChange}
                            handleRowClick={handleRowClick}
                            onSubmit={handleClauseSubmit}
                          />
                        ) : null}
                      </Col>
                    ) : (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          label="Agreement"
                          name="agreement_file"
                          valuePropName="fileList"
                          getValueFromEvent={(e) => e.fileList}
                        >
                          <Input
                            type="file"
                            onChange={(e) => {
                              setFilename(e.target.files[0]);
                              setCopyConfirmation(true);
                            }}
                            className="form-control"
                            accept=".doc, .docx"
                            required
                            beforeUpload={() => false}
                          ></Input>
                              {/* <Upload
                                fileList={fileList}
                                onChange={({file,fileList }) => {
                                  console.log(fileList,file, 'fileList 007')
                                  if (file.status === 'removed') {
                                    setFileList();
                                  } else if (file.status === 'done' || file.status === 'uploading') {
                                    setFilename(fileList[0].originFileObj);
                                    setCopyConfirmation(true);
                                  }}}
                                accept=".doc, .docx"
                                maxCount={1}
                                onRemove={()=>{ 
                                  // setautofillDoc(false)
                                   setFilename({});

                                }}
                              >
                                <Button>Upload</Button>
                              </Upload> */}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </Col>
                { !!autofillDoc && autofillDoc ? (
                  <Col span={15} style={{ marginTop:'-55px',overflow: 'auto' }} justifyContent="start">
                    {console.log('003 inside condition')}
                    <EditDocComponent
                      // fileUrl={ !!autofillDoc ? 'http://192.168.29.136:8081/'+autofillDoc : ''}
                      fileUrl={ !!autofillDoc ? autofillDoc : ''}

                      userName={localStorage.getItem("user_name")}
                      isSave={false}
                    />
                  </Col>
                ) : ''}
              </Row>

              <Modal
                title=""
                open={copyConfirmation}
                onOk={() => getAutofillDoc(false)}
                onCancel={() => setCopyConfirmation(false)}
                okText={isLoading ? "Uploading.." : "Yes"}
                cancelText="No"
                okButtonProps={{ disabled: isLoading }}
              >
                You have selected a document as agreement file. Press ‘Yes’ if
                you intend to proceed with the autofill.
              </Modal>
            </TabPane>
            <TabPane   tab="Products / Services" key="4">
              <Row gutter={16}>
                <Col span={ !!autofillDoc ? 9 : 24} xs={24} sm={24} md={12} lg={12}>
                  <Form.Item label="Product / Service Name" name="product_list">
                    <CreatableSelect
                      isMulti
                      options={productOptions}
                      required
                      onChange={handleProductChange}
                    />
                  </Form.Item>
                </Col>
                {selectedProducts && selectedProducts.length > 0 ? ( // Render the Table only when there are selected products
                  <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    bordered
                    className="mb-4 product-table"
                    scroll={{ x: "max-content" }}
                  />
                ) : (
                  ""
                )}
               { !!autofillDoc ? (
                  <Col span={15} style={{ marginTop:'-55px',overflow: 'auto' }} justifyContent="start">
                    <EditDocComponent
                      fileUrl={ !!autofillDoc ? autofillDoc : ''}
                      userName={localStorage.getItem("user_name")}
                      isSave={false}
                    />
                  </Col>
                ) : ''}
              </Row>
            </TabPane>

            <TabPane   tab="Custom Field" key="5">
              <Row gutter={16}>
                <Col span={ !!autofillDoc ? 9 : 12}  >
                  <h3 className="mb-7 bg-slate-100 py-2 pl-6">Custom Fields</h3>

                  {customFields.map((field, index) => (
                    <Row key={index} gutter={16}>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Form.Item label={`Particular ${index + 1}`}>
                          <Input
                            value={field.field}
                            onChange={(e) =>
                              handleCustomFieldChange(
                                index,
                                e.target.value,
                                field.value
                              )
                            }
                            // required
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Form.Item label={`Details ${index + 1}`}>
                          <Tooltip placement="right" title={field.value}>
                            <TextArea
                              autoSize={{
                                minRows: 1,
                              }}
                              value={field.value}
                              onChange={(e) =>
                                handleCustomFieldChange(
                                  index,
                                  field.field,
                                  e.target.value
                                )
                              }
                              required
                            />
                          </Tooltip>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8}>
                        <Button
                          type="danger"
                          onClick={() => handleRemoveCustomField(index)}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Button
                    type="dashed"
                    onClick={handleAddCustomField}
                    className="mb-4"
                    block
                  >
                    <PlusCircleOutlined />
                    Add Custom Field
                  </Button>
                </Col>
                { autofillDoc ? (
                  <Col span={15} style={{ marginTop:'-55px',overflow: 'auto' }} justifyContent="start">
                    <EditDocComponent
                      fileUrl={ !!autofillDoc ? autofillDoc : ''}
                      userName={localStorage.getItem("user_name")}
                      isSave={false}
                    />
                  </Col>
                ) : ''}
              </Row>
            </TabPane>
            <TabPane   tab="Reciever's Email" key="6">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <h3 className="mb-7 bg-slate-100 py-2 pl-6">
                    Receiver's Emails
                  </h3>
                  <Form.Item
                    label=""
                    name=""
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one receiver's email",
                      },
                    ]}
                  >
                    <Select
                      options={companyUsers
                        .sort((a, b) => a.label.localeCompare(b.label)) // Sort alphabetically by name
                        .map(user => ({
                          value: user.value, // Use email as the value
                          label: `${user.label} (${user.role})`, // Display name with role
                        }))}
                      isMulti
                      required
                      closeMenuOnSelect={false}
                      onChange={handleReceiversEmailChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Form>
        {/* { !!autofillDoc && (
          <div className="w-[100vh] h-[100vh] ml-2">
            <EditDocComponent
              fileUrl={ !!autofillDoc ? autofillDoc : ''}
              userName={localStorage.getItem("user_name")}
              isSave={false}
            />
          </div>
        )} */}
      </div>

      <div
        style={{
          // marginTop: "10rem",
          bottom: 0,
          left: 0,
          width: "105.1%",
          display: "flex",
          justifyContent: "flex-start",
          height: "27vh",
          marginBottom: "-26px",
          marginLeft: "-24px",
          backgroundColor: "#EBE8F7",
          paddingTop: "30px",
        }}
      >
        {activeTab !== "1" && (
          <ButtonComp
            text="Previous"
            textColor="#4E36A3"
            bgColor="white"
            onClick={handlePrevious}
            margin="2rem"
          />
        )}
        {activeTab !== "6" ? (
          <ButtonComp
            text="Next"
            bgColor="#4E36A3"
            textColor="white"
            onClick={handleNext}
            margin="2rem"
          />
        ) : (
          <ButtonComp
            text={isLoading ? "Saving.." : "Save as Draft"}
            bgColor="#4E36A3"
            textColor="white"
            onClick={handleSubmit}
            isDisabled={isLoading ? true : false}
          />
        )}
      </div>
    </div>
  );
};

export default InitiateRequest;
