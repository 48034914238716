import React, { useState, useEffect } from "react";
import { Table, Input, Tooltip } from "antd";
import axios from '../../utils/axios';
import { API_URL } from "../../utils/Constant";
import { Link } from "react-router-dom";
import { formatIndianDate } from "../../utils/Constant";
import { LoadingOutlined } from "@ant-design/icons";
import ButtonComp from "../../components/button/ButtonComp";
import { capitalizeAndRemoveUnderscores, limitWords } from "../../utils/utils";
import { IoSearch } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";  // Import FaDownload for the download button icon
import Papa from 'papaparse';

const { Search } = Input;

function AllRequests() {
  const token = localStorage.getItem("access_token");
  const [isLoading, setIsloading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(false);
  let [request, setRequest] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const queryId = queryParams.get('id');



  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page_size: 100,
      search_keyword: "",
    },
  };

  const fetchContracts = async () => {
    try {
      const response = await axios.get(API_URL + "/contract/get/", config);
      setIsloading(false);
      if(queryId){ // filter by fronend due to backend not able to do it.
        setRequest(response.data.filter(item=> item.id == queryId)); // Update the state with the fetched user data
      }else{
        setRequest(response.data); // Update the state with the fetched user data
      }
    } catch (error) {
      console.log(error)
    }
  };
  

  useEffect(() => {
    // Fetch user data from the API
    setIsloading(true); 
    fetchContracts();
  }, []);

  const handleSearch = (value, event) => {
    event.preventDefault();
    config.params.search_keyword = value;
    setSearchKeyword(value)
    setIsloading(true); 
    fetchContracts()
  };

  const handleDownloadAll = () => {
    const csvData = request.map(contract => ({
      "User Name": contract.user_name,
      "Date of Raising": formatIndianDate(contract.created_at),
      "Agreement Type": contract.agreement_type,
      "Partner Name": contract.name_of_client,
      "Nature": contract.nature,
      "Status": capitalizeAndRemoveUnderscores(contract.status),
      "Remarks": contract.remarks,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "contracts.csv";
    link.click();
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      title: (
        <div>
          Date of Raising <br />
          (dd-mm-yyyy)
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "Agreement Type",
      dataIndex: "agreement_type",
    },
    {
      title: "Partner Name",
      dataIndex: "name_of_client",
      render: text => text && text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Nature",
      dataIndex: "nature",
      render: text => text && text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: text => capitalizeAndRemoveUnderscores(text),
    },
    {
      title: "Remarks(if any)",
      dataIndex: "remarks",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link to={`${record.id}`}>
          <button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View details
          </button>
        </Link>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {};
  const showExtraFieldModal = () => {};

  return (
    <>
      {isLoading ? (
        <div className="text-center top-3 left-3">
          <LoadingOutlined style={{ fontSize: "5rem" }} />
        </div>
      ) : (
        <div>
          <div className=" text-black pb-3 px-2 flex items-center justify-between">
            <div className="flex flex-row justify-center align-middle">
              <span>
                <h1
                  className="mt-1 text-lg font-medium "
                  style={{
                    fontWeight: "700",
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "30px",
                  }}
                >
                  Contracts
                </h1>
              </span>
              <div className="pl-4">
                <Search
                  placeholder="Search..."
                  onSearch={handleSearch}
                  enterButton={<IoSearch />}
                />
              </div>
            </div>
            <Link to="/admin/create_request">
              <ButtonComp
                text="Raise New Request"
                bgColor="#4E36A3"
                textColor="white"
                onClick={showExtraFieldModal}
              />
            </Link>
          </div>
          
          <Table
            columns={columns}
            dataSource={request}
            onChange={onChange}
            scroll={{ x: "max-content" }}
          />
          
          {/* Download Button */}
          <Tooltip title={"Download All Contracts"}>
            <button
              onClick={handleDownloadAll}
              style={{
                backgroundColor: '#36A34A',
                color: 'white',
                padding: '8px 16px',  // Adjust padding for smaller button
                fontSize: '14px',      // Adjust font size to match previous size
                position: 'relative',
                marginTop: '20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Download All Contracts
            </button>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default AllRequests;
