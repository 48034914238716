import React, { useRef, useEffect, useState,useCallback } from "react";
import "./button.css";
import {
  DocumentEditorContainerComponent,
  Toolbar,
  WordExport,
  SfdtExport,
} from "@syncfusion/ej2-react-documenteditor";
import { registerLicense } from "@syncfusion/ej2-base";
import { Button } from "antd";
import { syncServiceUrl } from "../utils/Constant";
import { message } from "antd";
// const syncServiceUrl = process.env.REACT_SYNC_SERVICE_URL

DocumentEditorContainerComponent.Inject(Toolbar);
DocumentEditorContainerComponent.Inject(WordExport);
DocumentEditorContainerComponent.Inject(SfdtExport);
const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfVldXHxLflFyVWJYdV54fldBcC0sT3RfQFljTH5Rd0RjUH1bd3NVQw=="
);

function EditDocComponent({
  fileUrl,
  isEditable,
  userName,
  onClose,
  isSave = true,
  hasBeenShown,
}) {
  const containerRef = useRef(null);
  const [fileContent, setFileContent] = useState(null);
  const isApiCalling = useRef(false)
  const toolItem = {
    prefixIcon: "e-save",
    tooltipText: "Save",
    text: "Save",
    id: "Save",
    disabled: hasBeenShown,
  };
  const toolbarItems = [
    "New",
    "Open",
    toolItem,
    "Separator",
    "Undo",
    "Redo",
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    "TableOfContents",
    "Separator",
    "Header",
    "Footer",
    "PageSetup",
    "PageNumber",
    "Break",
    "InsertFootnote",
    "InsertEndnote",
    "Separator",
    "Find",
    "Separator",
    "Comments",
    "TrackChanges",
    "Separator",
    "LocalClipboard",
    "RestrictEditing",
    "Separator",
    "FormFields",
    "UpdateFields",
  ];

  const onCreated = () => {
   

    // const uploadDocument = new FormData();
    // uploadDocument.append("DocumentName", fileUrl);
    // const url = container.serviceUrl + "LoadDocument";
    // const httpRequest = new XMLHttpRequest();
    // httpRequest.open("POST", url, true);
    // httpRequest.onreadystatechange = function () {
    //   if (httpRequest.readyState === 4) {
    //     if (httpRequest.status === 200) {
    //       container.documentEditor.open(httpRequest.responseText);
    //     }
    //   }
    // };
    // httpRequest.send(uploadDocument);
    
    loadDocument()

    // setInterval(() => {
    //   updateDocumentEditorSize();
    // }, 100);

    // window.addEventListener("resize", onWindowResize);
  };

  const loadDocument = async () => {
    try {
      const container = containerRef.current;
      container.documentEditor.currentUser = userName;
      container.documentEditor.userColor = "#fff000";
      container.documentEditor.restrictEditing = false;
      const uploadDocument = new FormData();
      uploadDocument.append("DocumentName", fileUrl);
      const url = container.serviceUrl + "LoadDocument";

        if(!isApiCalling.current){
          isApiCalling.current = true
          const response = await fetch(url, {
            method: "POST",
            body: uploadDocument,
          });
      
          if (!response.ok) {
            throw new Error("Failed to load the document");
          }
          isApiCalling.current = false;
          const data = await response.text();
          container.documentEditor.open(data);
        }

    } catch (error) {   
      isApiCalling.current = false;
      console.error("Error loading document:", error);
    }
  };


  const onSave = async () => {
    const container = containerRef.current;

    if (container && container.documentEditor) {
      const blob = await container.documentEditor.saveAsBlob("Docx");
      setFileContent(blob);
      return blob;
    } else {
      console.error("Document editor is not available.");
    }
  };

  const onWindowResize = () => {
    updateDocumentEditorSize();
  };

  const debouncedResize = useCallback(debounce(onWindowResize, 200), []);

  useEffect(() => {
    if(debouncedResize){
      console.log('resize 006');
      window.removeEventListener("resize", debouncedResize);
      window.addEventListener("resize", debouncedResize);
    }
    return () => window.removeEventListener("resize", debouncedResize);
  }, [debouncedResize]);




  const updateDocumentEditorSize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const container = containerRef.current;
    console.log({windowWidth,windowHeight})
    console.log(container)
    if(container && container.documentEditor){
      container.documentEditor.resize(windowWidth, windowHeight);
    }
  };

  const handleClose = async () => {
    if (onClose && typeof onClose === "function") {
      const blob = await onSave();
      onClose(blob);
    }
  };

  const onSelectionChange = () => {
    const container = containerRef.current;
    if (container && container.documentEditor) {
      const selectedText = container.documentEditor.selection.text;
      if (selectedText) {
        navigator.clipboard &&
          navigator.clipboard
            .writeText(selectedText)
            .then(() => {
              console.log("Text copied to clipboard", selectedText);
              // setTimeout(message.success("Copied to clipboard"), 700)
              // if (!hasBeenShown) {
              //     message.success(selectedText + "Copied to clipboard")
              //     setHasBeenShown(true);

              //     // Reset the state to false after 5 seconds (5000 milliseconds)
              //     setTimeout(() => {
              //       setHasBeenShown(false);
              //     }, 700); // Adjust the time as needed
              //   }
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
            });
      }
    }
  };

  const onToolBarClick = (args) => {
    switch (args.item.id) {
      case "Save":
        handleClose();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log('close handleBeforeUnload 006',fileContent)
      handleClose();
    };
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [fileContent]);

  return (
    <div>
      <div id="default_title_bar" className="e-de-ctn-title mb-14"></div>
      {/* {isSave && <Button onClick={handleClose} type='primary'>Save</Button>} */}
      <DocumentEditorContainerComponent
        id="container"
        ref={containerRef}
        height={"590px"}
        serviceUrl={`${syncServiceUrl}api/documenteditor/`}
        enableToolbar={true}
        created={onCreated}
        currentUser={userName}
        toolbarItems={toolbarItems}
        toolbarClick={onToolBarClick}
        selectionChange={onSelectionChange}
      />
    </div>
  );
}

export default EditDocComponent;