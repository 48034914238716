import axios from '../../utils/axios';
import React, { useState, useEffect } from "react";
import { signupUser } from "../../api/api";
import SuccessModal from "../../components/modal/SuccessModal";
import { Form, Input, Button, message } from "antd";
import { API_URL } from "../../utils/Constant";
import Select from "react-select";
function CreateUser() {
  // const [errorMessage, setErrorMessage] = useState("");
  // const [message, setMessage] = useState("");
  const navigation = false;
  const [first_name, setFirst_name] = useState("");
  const [email, setAdmin_email] = useState("");
  const [employee_id, setEmplyeeId] = useState("");
  const [role, setRole] = useState("");
  const [business_head_id, setBusinessHeadId] = useState("");

  const [department, setDepartment] = useState("");
  const [departments, setDepartments] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [businessHead, setBusinessHead] = useState();

  const [created, setCreated] = useState(true);
  const [error, setError] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const flag = localStorage.getItem("setModal");
    if (flag && flag === "true") {
      setModalVisible(true);
      localStorage.removeItem("setModal");
    }
  }, []);

  const token = localStorage.getItem("access_token");

  const generateConfig = (customParams = {}) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...customParams, // Merge customParams into the params object
      },
    };
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const config = generateConfig({ is_active: "False", page_size: 100 });
        const response = await axios.get(API_URL + "/department/get/", config);
        setDepartments(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchDepartment();
  }, []);

  const handleRole = (value) => {
    setRole(value);
  };
  const handleBusinessHeadId = (value) => {
    setBusinessHeadId(value);
  };

  useEffect(() => {
    if (role === "Businessteam") {
      fetchHeadName({ role: "Businesshead" });
    } else if (role === "Agreement controller Team") {
      fetchHeadName({ role: "Agreement controller Head" });
    }
  }, [role]);

  const fetchHeadName = async (props) => {
    try {
      const config = generateConfig(props);
      const response = await axios.get(API_URL + "/user/get/", config);
      setBusinessHead(response.data);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const config = generateConfig({});
      const payload = {
        email,
        employee_id,
        first_name,
        role,
        department,
      };
      if (business_head_id) {
        payload.business_head_id = parseInt(business_head_id, 10);
      }
      const { data, res } = await signupUser(payload, config);
      if (res.status === 201) {
        setDepartment("");
        setEmplyeeId("");
        setFirst_name("");
        setAdmin_email("");
        setRole("");
        setBusinessHeadId("");
        setIsLoading(false);
        setCreated(true);
        localStorage.setItem("setModal", true);
        message.success("User Created Successfully.");
        window.location.href = "/admin/inactive_users";
      }
    } catch (err) {
      setIsLoading(false);
      // setError(true);
      message.error(err.error);
    }
  };

  return (
    <>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-lg mx-auto">
        <h1 className="text-3xl font-semibold text-center text-blue-500 mb-6">
          Add New User
        </h1>
        {localStorage.getItem("setModal") === "true" ? (
          <SuccessModal
            modal="true"
            message="User created successfully. A link has been sent to the user's email to reset their password."
            link="/admin/inactive_users"
          />
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="first_name"
              
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name of User
            </label>
            <input
              name="first_name"
              type="text"
              onChange={(e) => setFirst_name(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email Address
            </label>
            <input
              name="email"
              type="email"
              onChange={(e) => setAdmin_email(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="admin_contact_number"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Employee ID
            </label>
            <input
              name="admin_contact_number"
              type="text"
              onChange={(e) => setEmplyeeId(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              title="Please enter a 10-digit contact number."
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="department"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Department
            </label>
            <select
              id="department"
              name="department"
              onChange={(e) => setDepartment(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select Department</option>
              {Object.entries(departments).map(([depId, department]) => (
                <option key={depId} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="role"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Role
            </label>
            <select
              id="role"
              name="role"
              onChange={(e) => handleRole(e.target.value)}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Select Role</option>
              <option value="Businessteam">Business Team</option>
              <option value="Businesshead">Business Head</option>
              <option value="Agreement controller Team">Agreement Control Team</option>
              <option value="Agreement controller Head">Agreement Control Head</option>
            </select>
          </div>

          {role && role === "Businessteam" ? (
            <div className="mb-4">
              <label
                htmlFor="role"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Business Head Name
              </label>
              <select
                id="business_head_id"
                name="business_head_id"
                onChange={(e) => handleBusinessHeadId(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                <option value="" className="bg-green-400">
                  Select Business Head Name
                </option>
                {businessHead &&
                  businessHead.map((businesshead) => (
                    <option key={businesshead.id} value={businesshead.id}>
                      {businesshead.first_name}
                    </option>
                  ))}
              </select>
            </div>
          ) : (
            ""
          )}

          {role && role === "Agreement controller Team" ? (
            <div className="mb-4">
              <label
                htmlFor="role"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Agreement Control Head
              </label>
              <select
                id="business_head_id"
                name="business_head_id"
                onChange={(e) => handleBusinessHeadId(e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              >
                <option value="" className="bg-green-400">
                  Select Agreement Control Head Name
                </option>
                {businessHead &&
                  businessHead.map((businesshead) => (
                    <option key={businesshead.id} value={businesshead.id}>
                      {businesshead.first_name}
                    </option>
                  ))}
              </select>
            </div>
          ) : (
            ""
          )}

          <div className="text-center">
            <button
              type="submit"
              disabled={isLoading}
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "Creating..." : "Add"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateUser;
