import React from 'react';
import { Result, Button } from 'antd';  // Importing Result and Button from Ant Design
import { Link } from 'react-router-dom';  // Importing Link from React Router
import { useNavigate } from 'react-router-dom';


const ErrorPage = () => {
    const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',  // Ensure the div takes up the full height of the viewport
        textAlign: 'center',
        flexDirection: 'column',  // Stack items vertically
        backgroundColor: '#f4f4f4',  // Optional background color
      }}
    >
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong on our side."
        extra={
          <Button onClick={()=>{ navigate(-1)}} type="primary">
           Go Back
          </Button>
        }
      />
      {/* Optionally, you can add an image below the Result */}
      {/* <div style={{ marginTop: '20px' }}>
        <img
        //   src="https://via.placeholder.com/600x400?text=Error+Image"
        src="https://images.unsplash.com/photo-1590510216513-c7e7638a66b7?crop=entropy&cs=tinysrgb&fit=max&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDEwfHxwb3J0cmFpdHxlbnwwfDB8fHwxNjg3Mjk4NzM5&ixlib=rb-1.2.1&q=80&w=1080"

          alt="Error"
          style={{ width: '100%', maxWidth: '400px' }}  // Adjust max width to fit on screen
        />
      </div> */}
    </div>
  );
};

export default ErrorPage;
