// // Check  number or not;
//  Created by Abadat 
export const isNumberCheck=(inputValue)=>{
    if (!/^\d*\.?\d*$/.test(inputValue)) {
        return false;
      }
      return true;
}
export const calCulateEllipsis=(value)=>{
  if(value.length < 30){
    return value
  }
  const val = value.slice(0,30);
  return val+'...';
}