import axios from "axios";
import { openNotification } from "./notification";
import { useNavigate } from "react-router-dom";
import {message} from "antd";

const instance = axios.create();
// const navigate = useNavigate();
let unauthorizedNotificationShown = false;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const isAdminRoute = window.location.pathname.startsWith("/admin");
    if (isAdminRoute) {
    if (error.response && error.response.status === 401) {
      if (!unauthorizedNotificationShown) {
        unauthorizedNotificationShown = true;
        // Handle unauthorized access, e.g., redirect to login page
        openNotification(
          "error",
          "Unauthorized Access",
          "You do not have permission to access this page. Please log in again."
        );
        setTimeout(() => {
          window.location.href = "/auth/login";
        }, 3000);
      }
    } else if (error.response && error.response.status === 500) {
      // Handle Internal Server Error
      openNotification(
        "error",
        "Internal Server Error",
        "An internal server error occurred. Please try again later."
      );
      // You can choose to redirect or show a different error message based on your requirements
    }
  }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
        return response;  // You can return only the response data if you want
  },
  (error) => {
      // Handle any errors in the response
      const {status,data} = error.response;
      if(status === 400){
        message.error(data.error);
        // return false;
      }else if(status === 401){

      }else if(status === 404){
        
      }else if(status === 403){

      }else if(status === 500){
        window.location.href = '/error';
        return false
      }else{

      }
      return Promise.reject(error);  // Reject the error to propagate it
  }
);

export default instance;
