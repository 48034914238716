// RightSide.jsx
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Col, Select } from "antd";
import Logo from "../../assets/images/logo.jpeg";
import { NavLink, useNavigate } from "react-router-dom";
import { message } from "antd";
import { API_URL } from "../../utils/Constant";
import axios from "../../utils/axios";
import { sendRequestDemo } from "../../api/api";
import { openNotification } from "../../utils/notification";

const { Option } = Select;

const DemoRequestComponent = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const [country, setCountry] = useState(); // Add state for country
  const [states, setStates] = useState();
  const [form] = Form.useForm();

  const handleCountryChange = (e) => {
    const selectedCountryId = e;
    const selectedCountry = countries[selectedCountryId - 1];
    form.setFieldValue('state', undefined)
    const countryCode = selectedCountry ? selectedCountry.country_code : "";
    setCountryCode(countryCode);
    setCountry(e);
    setStates([]);
    fetchState(selectedCountryId);
  };


  const fetchState = async (value) => {
    try {
      const response = await axios.get(API_URL + "/state/get/", {
        params: {
          country_id: value,
        },
      });

      setStates(response.data); // Update the state with the fetched user data
    } catch (error) {}
  };
  const onFinish = async (values) => {
    console.log(values, 'values')
    const data = {
      ...values,
      address: {
        country: parseInt(values.country),
        state: parseInt(values.state),
      },
    };
    delete data.country;
    delete data.state;
    try {
      setIsLoading(true);
      const { res } = await sendRequestDemo({ data });
      if (res.status === 200) {
        setIsLoading(false);
        // message.success(
        //   res.data.message
        // );
        openNotification(
          "success",
          "Email Sent Successfully.",
          "We have recieved your request. Our team will reach you soon."
        );

        setTimeout(() => {
          navigate("/auth/login");
        }, 2000);
      } else {
        message.error(res);
        setIsLoading(false);
      }
    } catch (err) {
      message.error(err.error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(API_URL + "/country/get/");
        setCountries(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchCountry();
  }, []);

  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: "80%", width: 400 }}>
          <div
            style={{
              textAlign: "left",
              marginBottom: 20,
              fontSize: "40px",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>
              <span className="text-indigo-600">C</span>ore
              <span className="text-indigo-600">V</span>entum
            </h1>
          </div>
          <h1
            style={{
              lineHeight: "38.73px",
              fontWeight: "700",
              fontSize: "30px",
              marginBottom: "2rem",
            }}
          >
            Request Demo
          </h1>
          <Form  form={form} onFinish={onFinish}>
            <Col>First Name</Col>
            <Form.Item rules={[{ required: true, message: 'Please input your First Name!' }]} name="first_name" label="" style={{ marginBottom: 20 }}>
              <Input placeholder="Enter your first name" required />
            </Form.Item>
            <Col>Last Name</Col>
            <Form.Item rules={[{ required: true, message: 'Please input your Last Name!' }]} name="last_name" label="" style={{ marginBottom: 20 }}>
              <Input placeholder="Enter your last name" required />
            </Form.Item>
            <Col>Email Address</Col>
            <Form.Item  rules={[{ required: true, message: 'Please input your Email!' }]} name="email" label="" style={{ marginBottom: 20 }}>
              <Input
                type="email"
                placeholder="Enter your email address"
                required
              />
            </Form.Item>
            <Col>Contact No</Col>
            <Form.Item rules={[{ required: true, message: 'Please input your Number!' }]} name="contact_no" label="" style={{ marginBottom: 20 }}>
              <Input
                type="contact_no"
                placeholder="Enter your contract No address"
                required
              />
            </Form.Item>
            <Col>Entity Name</Col>
            <Form.Item rules={[{ required: true, message: 'Please input your Entity!' }]} name="entity_name" label="" style={{ marginBottom: 20 }}>
              <Input
                type="entity_name"
                placeholder="Enter your Entity Name address"
              />
            </Form.Item>

            <Col>Country</Col>
            <Form.Item name="country"   rules={[{ required: true, message: 'Please select a Country!' }]} // Make it required
               label="" style={{ marginBottom: 20 }}>
              <Select
                showSearch
                placeholder="Select your country"
                style={{ width: "100%" }}
                onChange={handleCountryChange}
                optionFilterProp="children" // specify which property of the Option will be used for the search
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                
              >
                {countries &&
                  countries.map((country) => (
                    <Option key={country.id} value={country.id}>
                      {country.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Col>State</Col>
            <Form.Item rules={[{ required: true, message: 'Please select a State!' }]} name="state" required label="" style={{ marginBottom: 20 }}>
              <Select
                showSearch
                placeholder="Select your state"
                disabled={!form.getFieldValue('country')} 
                style={{ width: "100%" }}
                // onChange={handleCountryChange}
                optionFilterProp="children" // specify which property of the Option will be used for the search
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                
              >
                {states &&
                  states.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {/* <Col>State</Col>
            <Form.Item name="state" label="" style={{ marginBottom: 20 }}>
              <Select
                placeholder="Select your state"
                style={{ width: "100%" }}
                onChange={handleCountryChange}
              >
                {states &&
                  states.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item> */}

            {/* <Col>Contact Number</Col>
            <Form.Item
              name="phone"
              label=""
              style={{ marginBottom: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please input your contact number!",
                },
                {
                  pattern: /^\d{10}$/,
                  message: "Please input a 10-digit phone number!",
                },
              ]}
            >
              <Input
                addonBefore={countryCode ? countryCode : "+91"}
                placeholder="Enter your contact number"
              />
            </Form.Item> */}
            {/* <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 24 }}
            >
              <Col>
                <Form.Item name="agree" valuePropName="checked" noStyle>
                  <Checkbox required></Checkbox>
                  <span
                    style={{
                      userSelect: "none",
                      pointerEvents: "none",
                      paddingLeft: "8px",
                    }}
                  >
                    By clicking here, I state that I have read and understood
                    the terms and conditions policy
                  </span>
                </Form.Item>
              </Col>
            </Row> */}
            <Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                style={{ backgroundColor: "#4E36A3", borderColor: "#4E36A3" }}
                // disabled={isLoading ? true : false}
                loading={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
            <div style={{ textAlign: "left" }}>
              <p>
                Already a user?{" "}
                <NavLink
                  to={"/auth/login/"}
                  style={{ color: "#4E36A3", fontWeight: "bold" }}
                >
                  Log In
                </NavLink>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default DemoRequestComponent;
