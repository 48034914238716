import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import { API_URL } from '../../utils/Constant';

const StatusDetail = () => {
    const { status } = useParams(); // Get the status from the URL
    const [details, setDetails] = useState(null);

    useEffect(() => {
        const fetchStatusDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/status/details/${status}`);
                setDetails(response.data);
            } catch (error) {
                console.error("Error fetching status details:", error);
            }
        };

        fetchStatusDetails();
    }, [status]);

    if (!details) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Status Details for: {status}</h1>
            {/* Render the details here */}
            <pre>{JSON.stringify(details, null, 2)}</pre>
        </div>
    );
};

export default StatusDetail;