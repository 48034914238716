import React, { useState } from 'react';
import {
    PlusOutlined,
    MinusCircleOutlined
  } from "@ant-design/icons";
  import {
    Button,
    Input,
    Modal,
    Form
  } from "antd";


const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 4,
      },
    },
  };

export default function AddModel({title, isOpen, handleCancelModal,from,name, onFinish}) {
  return (
    <Modal
    title={title}
    open={isOpen}
    onCancel={handleCancelModal}
    footer={null}
  >
    <Form
      form={from}
      name={name[0]}
      {...formItemLayoutWithOutLabel}
      onFinish={onFinish}
      open={isOpen}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.List name={name[1]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 200
                  ? formItemLayout
                  : formItemLayoutWithOutLabel)}
                label={""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message:
                        "Please input Department name or delete this field.",
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="Department name"
                    style={{
                      width: "60%",
                    }}
                  />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button ml-3"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <div>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "60%",
                  }}
                  icon={<PlusOutlined />}
                >
                  Add Department
                </Button>
              </div>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item className="">
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </Modal>
  )
}
